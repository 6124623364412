/// <reference types="@types/segment-analytics" />

import { BenefitsAdvisorContext } from 'src/types';

export interface UserProperties {
  email?: string;
  name?: string;
  zip?: string;
  access_code?: string;
  access_code_first?: string;
  health_zip?: string;
  health_type?: 'FFM' | 'STATE_EXCHANGE';
  health_state?: string;
  health_income_estimate?: number;
  health_px?: boolean;
  health_px_session?: string;
  health_px_year?: 2023 | 2024;
  health_age?: number;
  health_plan_explorer?: string;
}

export enum SegmentEvent {
  // ctas
  TALK_TO_BENEFITS_ADVISOR_CLICKED = 'Talk to Benefits Advisor Clicked',

  // px pages
  EXPLORER_PLAN_CHOSEN = 'Explorer Plan Chosen',
  PX_PLAN_CHOSEN = 'PX Plan Chosen',
  PX_STARTED = 'PX Started',
  PX_SESSION_CREATED = 'PX Session Created',
  PX_OPEN_COMPARISON = 'PX Open Comparison',
  PX_PLAN_VIEWED = 'PX Plan Viewed',

  // misc
  PARTNER_FORM_SUBMITTED = 'Partner Form Submitted',
  APPLIED_TO_JOB = 'Applied to Job',

  EMAIL_OPTIN_SUBMITTED = 'Email Opt-In Submitted',

  // @deprecated?
  PX_OE23_REMINDER = 'PX OE23 Reminder',
  PX_EMAIL_ADDED = 'PX Email Added',
  HEALTH_ELIGIBILITY_ESTIMATED = 'Health Eligibility Estimated',
}

/**
 * @todo choose one: camel case or snake case
 */
type SegmentData = {
  [SegmentEvent.TALK_TO_BENEFITS_ADVISOR_CLICKED]: {
    context: BenefitsAdvisorContext;
  };

  [SegmentEvent.EXPLORER_PLAN_CHOSEN]: {
    sessionId: string;
    planName: string;
    healthContext: string;
  };

  [SegmentEvent.PX_PLAN_CHOSEN]: {
    sessionId: string;
    planName: string;
    healthContext: string;
  };

  [SegmentEvent.PX_STARTED]: {
    flow: 'Slasher' | 'Explorer' | 'Renew';
    healthContext?: string;
    partner?: string;
    pxId: string;
    adminPxLink: string;
  };

  [SegmentEvent.PX_SESSION_CREATED]: {
    pxId: string;
    adminPxLink: string;
  };
  [SegmentEvent.PX_OPEN_COMPARISON]: {
    planIds: Array<string>;
  };

  [SegmentEvent.PX_PLAN_VIEWED]: {
    pxId: string;
    adminPxLink: string;
    planId: string;
    carrier: string;
    issuer: string;
    carrier_group: string;
  };

  [SegmentEvent.PX_EMAIL_ADDED]: {
    email: string;
    e: string;
  };

  [SegmentEvent.PX_OE23_REMINDER]: {
    email: string;
    flow: 'Slasher' | 'Explorer' | 'Renew';
  };

  [SegmentEvent.HEALTH_ELIGIBILITY_ESTIMATED]: {
    pxid: string;
    email_address: string;
    estimated_aptc_amount: number;
    user_flow: 'slasher' | 'explorer' | 'renew';
    coverage_year: number;
    income: number;
  };

  [SegmentEvent.PARTNER_FORM_SUBMITTED]: {
    name: string;
    company: string;
    email: string;
    message: string;
  };

  [SegmentEvent.APPLIED_TO_JOB]: {
    userId: string;
    page: string;
    job: string;
  };

  [SegmentEvent.EMAIL_OPTIN_SUBMITTED]: {
    email: string;
    type?: 'fiverraetna';
  };
};

export const Segment = {
  identify: (traits: UserProperties) => {
    window.analytics.identify(traits);
  },
  track<Event extends keyof SegmentData>(event: Event, data: SegmentData[Event]) {
    window.analytics.track(event, data);
  },
};
