import React from 'react';
import { createIntl, createIntlCache, MessageDescriptor } from 'react-intl';
import { messages } from '../lang';

export const LOCALE_EN = 'en';
export const LOCALE_ES = 'es';
const DEFAULT_LOCALE = LOCALE_EN;

class IntlStorage {
  locale = DEFAULT_LOCALE;

  constructor() {
    this.locale =
      typeof window !== 'undefined'
        ? window.localStorage.getItem('locale') || DEFAULT_LOCALE
        : DEFAULT_LOCALE;

    this.setLocale = this.setLocale.bind(this);
    this.toggleLocale = this.toggleLocale.bind(this);
  }

  setLocale(locale) {
    this.locale = locale;

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('locale', locale);
      window.location.reload();
    }
  }

  toggleLocale() {
    this.setLocale(this.locale === LOCALE_EN ? LOCALE_ES : LOCALE_EN);
  }
}

const intlStorage = new IntlStorage();

export const locale = intlStorage.locale;
console.log('Using locale: ', locale);

export const toggleLocale = intlStorage.toggleLocale;

/**
 * optional, but highly recommended
 * prevents a memory leak
 */
const cache = createIntlCache();

/**
 * the intl object -- gets passed to the provider
 */
const intl = createIntl(
  {
    locale,
    messages: messages[locale],
  },
  cache,
);

interface TranslateProps extends MessageDescriptor {
  data?: Record<string, any>;
}

const richText = {
  b: (str) => <b>{str}</b>,
  m: (str) => <span className="m">{str}</span>,
};

/**
 * t is the main translate option
 * - it's named to provide brevity throughout the codebase
 * - the default message is required
 */
export const $t = (props: TranslateProps) => {
  return intl.formatMessage(
    {
      id: props.id,
      defaultMessage: props.defaultMessage,
    },
    {
      ...props.data,
      ...richText,
    },
  );
};
