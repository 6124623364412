import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
  Button,
  Container,
  Grid,
  Link,
  TextB2,
  TextB3,
  TextEb,
  TextWithIcon,
} from 'src/components';
import emails from 'src/constants/emails';
import paths from 'src/constants/paths';
import phoneNumbers from 'src/constants/phone-numbers';
import urls from 'src/constants/urls';
import { useIntercom } from 'src/lib';
import { $t } from 'src/utils';

const linkSpacing = '12px';

const SFooter = styled.footer`
  max-width: 100%;
  background: var(--c-bg-gray);
  overflow: hidden;
  padding: var(--side-margin);

  a {
    font-weight: 400;
  }

  div.xl {
    font-size: var(--fs-b2);
  }

  .eb {
    margin-bottom: 24px;
    opacity: 0.65;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: ${linkSpacing};
  }

  .copyright {
    border-top: 1px var(--c-border) solid;
    margin: 32px 0;
    padding: 32px 0 0;
  }

  .disclosures {
    > div {
      padding-right: var(--side-margin);
    }

    .b3 {
      color: var(--c-fg-1);
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 12px;

      strong {
        font-weight: 500;
      }
    }

    a {
      text-decoration: underline;
      text-decoration-color: var(--c-fg-3) !important;
      text-decoration-thickness: 1px !important;
      text-underline-offset: 2.5px !important;

      &:hover {
        color: var(--c-fg-0);
      }
    }
  }
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${linkSpacing};
`;

interface FooterProps {
  disclaimer?: ReactNode;
  email?: string;
  hideLinks?: boolean;
  phoneNumber?: string;
  phoneNumberFormatted?: string;
}

export function Footer({
  disclaimer,
  email = emails.HELP,
  phoneNumber = phoneNumbers.DEFAULT,
  phoneNumberFormatted = phoneNumbers.DEFAULT_FORMATTED,
  hideLinks,
}: FooterProps) {
  const handleIntercomOpen = useIntercom();

  return (
    <SFooter>
      <Container>
        <div className="footer">
          {!hideLinks && (
            <Grid num={4} style={{ width: '100%', marginTop: 24 }}>
              <div>
                <TextEb>{$t({ id: 'footer.company', defaultMessage: 'Company' })}</TextEb>
                <div className="links">
                  <Link to={paths.ABOUT}>
                    {$t({ id: 'footer.about', defaultMessage: 'About' })}
                  </Link>
                  <Link to={paths.PARTNERS}>
                    {$t({ id: 'footer.partners', defaultMessage: 'Partners' })}
                  </Link>
                  <Link to={paths.PRESS}>
                    {$t({ id: 'footer.press', defaultMessage: 'Press' })}
                  </Link>
                  <Link to={paths.TRUST}>
                    {$t({ id: 'footer.trust', defaultMessage: 'Trust' })}
                  </Link>
                  <Link to={paths.LICENSES}>
                    {$t({ id: 'footer.licenses', defaultMessage: 'Licenses' })}
                  </Link>
                  <Link to={paths.LEGAL}>
                    {$t({ id: 'footer.legal', defaultMessage: 'Legal' })}
                  </Link>
                  {/*
                  <Link to={paths.CAREERS}>{$t({ id: 'footer.careers', defaultMessage: 'Careers' })}</Link>
                  */}
                </div>
              </div>
              <div>
                <TextEb>{$t({ id: 'footer.resources', defaultMessage: 'Resources' })}</TextEb>
                <div className="links">
                  <Link to={paths.GUIDES_HEALTH}>
                    {$t({ id: 'footer.guides', defaultMessage: 'Guides' })}
                  </Link>
                  <Link to={paths.EXPLORER}>
                    {$t({ id: 'footer.explorer', defaultMessage: 'Plan Explorer' })}
                  </Link>
                  <Link to={paths.BLOG}>{$t({ id: 'footer.blog', defaultMessage: 'Blog' })}</Link>
                  <Link to={urls.SUPPORT}>
                    {$t({ id: 'footer.help', defaultMessage: 'Help Center' })}
                  </Link>
                </div>
              </div>
              <div>
                <TextEb>{$t({ id: 'footer.contact', defaultMessage: 'Get in touch' })}</TextEb>
                <p>
                  {$t({
                    id: 'footer.support-hours',
                    // keep in sync with contact page SUPPORT_HOURS
                    defaultMessage:
                      'Catch Support is available from 9am to 6pm ET, Monday through Friday excluding federal holidays.',
                  })}
                </p>
                <Contacts>
                  <Link to={email}>
                    <TextWithIcon block icon="Mail">
                      {email}
                    </TextWithIcon>
                  </Link>
                  <Link to={phoneNumber}>
                    <TextWithIcon block icon="Phone">
                      {phoneNumberFormatted}
                    </TextWithIcon>
                  </Link>
                  <Button variant="text" onPress={handleIntercomOpen}>
                    <TextWithIcon icon="Help">
                      {$t({
                        id: 'footer.support',
                        defaultMessage: 'Start a chat',
                      })}
                    </TextWithIcon>
                  </Button>
                </Contacts>
              </div>
              <div style={{ display: 'none' }}>
                <TextEb>{$t({ id: 'footer.follow-us', defaultMessage: 'Follow us' })}</TextEb>
                <Link to={paths.BLOG}>{$t({ id: 'footer.blog', defaultMessage: 'Blog' })}</Link>
                <Link to={urls.TWITTER}>
                  {$t({ id: 'footer.twitter', defaultMessage: 'Twitter' })}
                </Link>
                <Link to={urls.INSTAGRAM}>
                  {$t({ id: 'footer.instagram', defaultMessage: 'Instagram' })}
                </Link>
                <Link to={urls.FACEBOOK}>
                  {$t({ id: 'footer.facebook', defaultMessage: 'Facebook' })}
                </Link>
                <Link to={urls.LINKEDIN}>
                  {$t({ id: 'footer.linkedIn', defaultMessage: 'LinkedIn' })}
                </Link>
              </div>
            </Grid>
          )}
        </div>
        <div className="copyright">
          <TextB2 className="sb">© {new Date().getFullYear()} Catch</TextB2>
        </div>
        <div className="disclosures">
          <Grid num="2">
            <div>
              <TextB3>
                {$t({
                  id: 'general-disclaimer',
                  defaultMessage: `This website is operated by Catch Financial, Inc. (“Catch”); Catch Money, LLC (“Catch Money”); and Catch Insurance, LLC (“Catch Insurance”). See {licenses}.`,
                  data: {
                    licenses: <Link to={paths.LICENSES}>licenses</Link>,
                  },
                })}
              </TextB3>
              <TextB3>
                <strong>{$t({ id: 'footer.banking.title', defaultMessage: 'Banking' })}</strong>
                <br />
                {$t({
                  id: 'footer.banking.disclaimer',
                  defaultMessage: `Catch Money is a financial technology company and is not a bank. Banking services provided by TransPecos Banks, SSB, Member FDIC. Accounts are eligible for pass-through deposit insurance only to the extent pass-through insurance is permitted by the rules and regulations of the FDIC, and if the requirements for pass-through insurance are satisfied. There may be a risk that pass-through deposit insurance is not available because conditions  have not been satisfied. In such cases, funds may not be fully insured in the event the insured depository institution where the funds have been deposited were to fail.`,
                })}
              </TextB3>
            </div>
            <div>
              <TextB3>
                <strong>
                  {$t({ id: 'health-insurance', defaultMessage: 'Health Insurance' })}
                </strong>
                <br />
                {$t({
                  id: 'health-disclaimer',
                  defaultMessage: `Attention: This website is operated by Catch Insurance and 
                    is not the Health Insurance Marketplace® website. In offering this website, 
                    Catch Insurance is required to comply with all applicable federal law, including 
                    the standards established under 45 CFR §§155.220(c) and (d) and standards 
                    established under 45 CFR §155.260 to protect the privacy and security of personally 
                    identifiable information. This website may not support enrollment in all Qualified 
                    Health Plans (QHPs) being offered in your state through the Health Insurance 
                    Marketplace® website. For enrollment support in all available QHP options in 
                    your state, go to the Health Insurance Marketplace® website at {link}.`,
                  data: {
                    link: <Link to={urls.HEALTHCARE_GOV}>HealthCare.gov</Link>,
                  },
                })}
                {disclaimer && (
                  <>
                    <br />
                    <br />
                    {disclaimer}
                  </>
                )}
              </TextB3>
            </div>
          </Grid>
        </div>
      </Container>
    </SFooter>
  );
}

export default Footer;
