import React, { FormEvent } from 'react';
import { Button, FieldEmail, FieldWithButton } from 'src/components';

interface FormEmailFormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
}

interface FormEmailElement extends HTMLFormElement {
  readonly elements: FormEmailFormElements;
}

export type FormEmailSubmitEvent = FormEvent<FormEmailElement>;

interface FormEmailProps {
  buttonText?: string;
  label?: string;
  onSubmit: (event: FormEmailSubmitEvent) => void;
}

export default function FormEmail({
  buttonText = 'Submit',
  label = 'Email address',
  onSubmit,
  ...props
}: FormEmailProps) {
  return (
    <form onSubmit={onSubmit}>
      <FieldWithButton
        field={<FieldEmail {...props} label={label} required />}
        button={<Button>{buttonText}</Button>}
      />
    </form>
  );
}
